import React, { useState } from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

const Partners = ({ data }) => {
  const StyledOurClients = styled.div`
    .top-bg {
      height: 30rem;
      position: relative;
      h1 {
        position: absolute;
        bottom: -5rem;
        left: 50%;
        margin-bottom: 0;
        transform: translate(-50%, -50%);
        color: #ffffff;
        background: ${props => props.theme.colors.org};
        padding: 1.5rem 2.5rem;
        font-size: 2.5rem;
      }
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 8rem;
      .method {
        width: 22%;
        margin-bottom: 2rem;
        margin-right: 2rem;
        border-radius: 0.3rem;
        text-align: center;
        p {
          margin-top: 1.5rem;
        }
        .gatsby-image-wrapper {
          box-shadow: 2px 5px 10px #d6d6d6;
          height: 18rem;
          border-radius: 0.3rem;
        }
      }
    }
    .show-more {
      display: flex;
      justify-content: center;
      margin: 4rem 0 8rem;
      button {
        border: 2px solid ${props => props.theme.colors.org};
        background: transparent;
        padding: 1.5rem 3rem;
        border-radius: 3rem;
        font-size: 1.8rem;
        font-family: ${props => props.theme.fonts.sec};
        letter-spacing: 0.3rem;
        transition: 0.3s all;
        cursor: pointer;
        &:hover {
          background: ${props => props.theme.colors.org};
          color: #ffffff;
        }
      }
    }
    @media (max-width: 768px) {
      .top-bg { height: 20vh };
      .content {
        flex-wrap: wrap;
        .method {
          width: 43%;
        }
      }
    }
  `;

  const firstPage = data.allWordpressWpPartners.nodes.slice(0, 9);

  const [projects, setProjects] = useState(firstPage);
  const [allLoaded, setAllLoaded] = useState(false);

  const onClick = () => {
    let projectsRendered = projects;

    if (projects.length + 6 > data.allWordpressWpPartners.nodes.length) {
      projectsRendered = data.allWordpressWpPartners.nodes;
      setAllLoaded(true);
    } else {
      projectsRendered = data.allWordpressWpPartners.nodes.slice(0, projects.length + 6);
    }

    setProjects(projectsRendered);
  };

  const renderMoreBtn = () => {
    if(!allLoaded && data.allWordpressWpPartners.nodes.length > 9) {
      return (
        <button onClick={onClick}>Show More</button>
      )
    }
  };

  return (
    <Layout>
      <SEO title="Partners" />
      <StyledOurClients>
        <div className="top-bg">
          <Img fluid={data.file.childImageSharp.fluid} />
          <h1>PARTNERS</h1>
        </div>
        <div className="container">
          <div className="content">
            {projects.map((partner, i) => (
              <div key={i} className="method">
                <Img fluid={partner.acf.partner_image.localFile.childImageSharp.fluid} />
                <p>{partner.acf.partner_name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="show-more">
          {renderMoreBtn()}
        </div>
      </StyledOurClients>
    </Layout>
  );
};

export const data = graphql`
  {
    file(relativePath: { eq: "partners/partners.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allWordpressWpPartners {
      nodes {
        acf {
          partner_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          partner_name
        }
      }
    }
  }
`;

export default Partners;
